













/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import lang from '@lang/sidebar.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {},
    data() {
        return {
            active: false
        }
    },
    watch: {
        tooltipInd: function (val) {
            this.active = true;
            if (this.tooltip.duration) {
                setTimeout(() => {
                    this.active = false;
                }, this.tooltip.duration);
            }
        },
        $route (to, from){
            this.active = false;
        }
    },
    computed: {
        ...mapState(['tooltip', 'tooltipInd']),
    },
    methods: {
        deactivate() {
            this.active = false;
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class Tooltip extends Vue {};

