const validateQuestion = () => {
    const questionControlElement = document.querySelectorAll(`.question-block__control`)
            
    questionControlElement.forEach(element => {
        const button = (<HTMLDivElement>element.querySelector(".question-block__button"))

        button.addEventListener('click', function(event) {
            const input = (<HTMLInputElement> (<HTMLDivElement> element).querySelector('.question-block__answer'))
            const errorElement = (<HTMLDivElement>element.querySelector(".validation-message__error"))
            const answerElement = (<HTMLDivElement>element.querySelector(".validation-message__answer"))
            const correctElement = (<HTMLDivElement>element.querySelector(".validation-message__correct"))
           
            const answers = JSON.parse(input.dataset.answers)

            var valid = answers.map(answer => {
                const cleanedAnswer = answer.replace(/[\n\r]/g, '').toLowerCase();
                const cleanedValue = input.value.toLowerCase();

                if(cleanedValue.includes(cleanedAnswer)) return true

                return 
            }).includes(true)

            const giveAnswer = input.dataset.giveanswer.replace (/"/g, "")
           
            if(!valid && giveAnswer === "0") errorElement.classList.add('validation-message__error--visible')
            if(!valid && giveAnswer === "1") answerElement.classList.add('validation-message__answer--visible')
            if(valid) correctElement.classList.add('validation-message__correct--visible')

        input.addEventListener('input', function() { // hide message on input change
            correctElement.classList.remove('validation-message__correct--visible')
            errorElement.classList.remove('validation-message__error--visible')
            valid = null
        });
    })
    })
}


export default validateQuestion