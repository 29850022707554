














/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        props: ["minutes", "active"],
        data() {
            return {
                secondsPassed: 0,
                totalTime: (60000 / 1000) * this.minutes,
                totalSeconds: this.minutes * 60,
                size: 40,
                alertAt: [10, 5, 1],
                timeUp: false,
            };
        },
        computed: {
            ...mapState(['timerRight'])
        },
        watch: {
            active: function (val) {
                if (this.active) {
                    this.time();
                } else {
                    this.secondsPassed = 0;
                    this.timeUp = false;
                }
            }
        },
        methods: {
            normalize(val, start1, stop1, start2, stop2){
                return (((val-start1)/(stop1-start1))*(stop2-start2)+start2).toPrecision(3);
            },
            time() {
                setInterval(() => {
                    this.secondsPassed++;
                    let secondsLeft = this.totalSeconds - this.secondsPassed;
                    this.animateTimer();
                    this.alertAt.forEach(alertTime => {
                        if (secondsLeft === alertTime * 60) {
                            this.$store.dispatch('setToastMessage', `${alertTime} minute${alertTime > 1 ? 's' : ''} left!`)
                        }
                    });

                    if (secondsLeft == 0) {
                        this.$store.dispatch('setToastMessage', `Time's up!`)
                        this.timeUp = true
                    }

                }, 1000);
            },
            animateTimer() {
                let percentage = this.normalize(this.secondsPassed, 0, this.totalTime, 0, 100);
                if (percentage >= 101) {
                    return;
                }
                let cx = document.querySelector('.timer__canvas').getContext('2d');
                let total = 100;
                let currentAngle = -0.5 * Math.PI;
                let centerX = this.size / 2,
                    centerY = this.size / 2;
                let sliceAngle = (percentage / total) * 2 * Math.PI;
                let middleAngle = currentAngle + 0.5 * sliceAngle;
                
                cx.globalCompositeOperation = 'source-over';
                cx.clearRect(-100,-100, this.size + 100, this.size + 100);
                cx.beginPath();
                cx.arc(centerX, centerY, 100, currentAngle, currentAngle + sliceAngle);
                currentAngle += sliceAngle;
                cx.lineTo(centerX, centerY);
                cx.fillStyle = 'black';
                cx.fill();

                // clip circle
                cx.globalCompositeOperation = 'source-out';
                cx.beginPath();
                cx.arc(this.size / 2, this.size / 2, this.size / 2 - 2, 0, 2 * Math.PI);
                cx.fill();
            }
        },
        mounted() {
            if (this.active) {
                this.time();
            }
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class Timer extends Vue {};

