const validateOption = () => {
            const options = document.querySelectorAll(`.option__button`)
            
            options.forEach(option => {
                const errorElement = (<HTMLDivElement>option.parentElement.querySelector(".validation-message__error"))
                const correctElement = (<HTMLDivElement>option.parentElement.querySelector(".validation-message__correct"))

                option.addEventListener('click', function(event) {
                    const data = (<HTMLDivElement>event.currentTarget).dataset.correct.replace (/"/g, "");
                    const valid = data === "1";
                    valid && valid ? correctElement.classList.add('validation-message__correct--visible') : errorElement.classList.add('validation-message__error--visible')
                })
            })
}

export default validateOption