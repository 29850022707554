


























/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Mixins, Vue } from 'vue-property-decorator';
import { mapState, mapMutations } from 'vuex';
import { SlotsMixin } from '@fruitpackage/mixins';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import CrossSvg from '@svg/Cross.vue';
import lang from '@lang/sidebar.js';
import questionValidation from '../../validation/questionValidation.ts';
import optionValidation from '../../validation/optionValidation.ts';


/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        ArrowForwardSvg,
        CrossSvg
    },
    updated() {

        if(document.querySelector('.question-block__button')) {
            questionValidation()
        }

        if(document.querySelector('.question-block__options')) {
            optionValidation()
        }

        
    },
    data() {
        return {
            overlayContainer: null,
            scrollAmount: 600
        }
    },
    watch: {
        overlayVisible: function (val) {
            this.overlayContainer = document.querySelector('.overlay__container');
            this.overlayContainer && this.overlayContainer.scrollTo(0, 0);
        },
    },
    computed: {
        ...mapState(['overlayVisible', 'overlayContent', 'sessionData', 'preview', 'bmp', 'imprintBmp']),
        scrollable() {
            this.overlayContainer = document.querySelector('.overlay__container')
            if (!this.overlayContainer) return false;
            if (this.overlayContainer.scrollHeight > this.overlayContainer.offsetHeight) {
                return true;
            }
            return false;
        },
        lang() {
            return lang[this.activeLang()];
        },
    },
    methods: {
        ...mapMutations(['toggleOverlay']),
        clickBg(event) {
            let clickTarget = event.target.classList[0];
            clickTarget == 'overlay' && this.closeOverlay();
        },
        closeOverlay() {
            this.toggleOverlay(false);
        },
        scroll(dir) {
            let scrollPos = this.overlayContainer.scrollTop;
            let scrollTo = dir == 'up' ? this.scrollAmount * -1 : this.scrollAmount;

            this.overlayContainer.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': scrollPos + scrollTo
            });
        },
        saveDesign(e) {
            const action = e.target.getAttribute('action');

            // OPTIONS AFTER YOU FINISH DESIGNING

            // close overlay
            if (action == 'close') {
                this.closeOverlay()
            }
            
            // Post colors to db and continue
            if (action == 'save') {
                let images;

                images = [this.bmp];

                if (this.sessionData.product_type == 'sock' && this.sessionData.imprint)
                    images.push(this.imprintBmp);

                images.push(this.preview);

                this.postImages(this.sessionData.order_id, {
                    images: images.flat()
                });

                this.triggerOverlay(false);

                setTimeout(() => {
                    this.$router.push('/shipping')
                }, 200);
            }

            // Reset color choices
            if(action == 'reset') {
                this.resetColors()
                this.triggerOverlay(false);
            }

            // Reset color choices
            if(action == 'goBack') {
                this.triggerOverlay(false);
                this.$router.go(-1);
            }
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class PageMain extends Vue {};

