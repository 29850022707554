








import anime from 'animejs';

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {

    },
    data() {
        return {
            closeAfter: 4000,
            el: {},
            holder: {}
        };
    },
    computed: {
        ...mapGetters([
            'getToastData',
            'getToastMessage',
            'getToastButton',
            'getToastLink'
        ])
    },
    methods: {
        open: function() {
            anime({
                targets: this.el,
                top: 0,
                duration: 500,
                easing: 'easeInOutQuad',
                complete: () => {
                    setTimeout(() => {
                        this.close();
                    }, this.closeAfter);
                }
            });
        },
        close: function() {
            anime({
                targets: this.el,
                top: -160,
                duration: 500,
                easing: 'easeInOutQuad'
            });
        }
    },
    watch: {
        getToastMessage: {
            handler() {
                this.open(this);
            }
        }
    },
    mounted() {
        this.el = this.$el;
        this.holder = this.$el.querySelector('.toast__holder');
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class Toast extends Vue {

}

