




































/* ----------------------------------------
        Imports
 ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { app } from '@config';
import Overlay from '@part/layout/Overlay.vue';
import PageView from '@part/layout/PageView.vue';
import Toast from '@part/elements/Toast.vue';
import Tooltip from '@part/elements/Tooltip.vue';
import Timer from '@part/elements/Timer.vue';
import BackgroundImage from '@part/features/BackgroundImage.vue';
import BackgroundVideo from '@part/features/BackgroundVideo.vue';

/* ----------------------------------------
        Component
    ---------------------------------------- */

@Component({
    data() {
        return {
            overlayVisible: false,
            noBlurPages: ['home', 'finish'],
            noTimerPages: ['home', 'login', 'settings', 'finish']
        };
    },

    components: {
        BackgroundImage,
        BackgroundVideo,
        Overlay,
        PageView,
        Timer,
        Toast,
        Tooltip,
    },

    computed: {
        ...mapState(['sessionData', 'popupContent']),
        userType () {
            return this.sessionData.user_type == 1 ? 'consumer' : 'education';
        }
    },
    watch:{
        $route (to, from){
            this.fetchApi();

            // for education user type, popups open up automatically
            // currently commented out, so popup opens automatically always

            // if (state.sessionData.user_type == 2 && this.$router.currentRoute.name !== 'design') {
            if (this.$router.currentRoute.name !== 'design') {
                setTimeout(() => {
                    let overlayContent = document.querySelector('.overlay-content');
                    overlayContent && this.triggerOverlay(true);
                }, 800);
            }
        }
    } ,
    methods: {
        fetchApi () {
            // ifpopup content not available yet, fetch from api
            !this.popupContent && this.getPopupContent();

            // get order id if not available yet
            if (!this.sessionData.order_id || this.sessionData.product_type !== this.sessionData.order_type)
                this.getOrderId(this.sessionData.product_type);

            // put correct content inside overlay, based on current route
            this.placeOverlayContent();
        }
    },
    mounted() {
        this.fetchApi();
    }
})

/* ----------------------------------------
        Export
    ---------------------------------------- */
export default class Master extends Vue {}
