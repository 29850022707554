











/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';


/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: ['blurred', 'hidden'],
    methods: {
        imgSrc() {
            return `${window.location.origin}/assets/images/Background_1.png`;
        },
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class BackgroundImage extends Vue {};

