














/* ----------------------------------------
        Imports
    ---------------------------------------- */

import UserJob from '@job/UserJob';
import AppJob from '@job/AppJob';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import PageView from '@part/layout/PageView.vue';
import Toast from '@part/elements/Toast.vue';

/* ----------------------------------------
        Component
    ---------------------------------------- */

@Component({})

/* ----------------------------------------
        Export
    ---------------------------------------- */
export default class PageView extends Vue {}
