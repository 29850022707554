











/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: ['blurred', 'hidden'],
    data() {
        return {
            number: Math.floor(Math.random() * 2)
        };
    },
    methods: {
        posSrc() {
            return `${window.location.origin}/assets/images/Background_${this.number}.png`;
        },
        vidSrc() {
            return `${window.location.origin}/assets/videos/Background_${this.number}.mp4`;
        },
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class BackgroundVideo extends Vue {};

